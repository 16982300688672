<script>
import AppHeader from '@/components/shared/AppHeader';
import ProjectGallery from '../components/projects/ProjectGallery.vue';
import AppFooter from '@/components/shared/AppFooterNonHome.vue';
import axios from 'axios';

export default {
  name: 'BotOniel',
  components: {
    AppHeader,
    ProjectGallery,
    AppFooter,
  },
  data() {
    return {
      data: {
        jokes: 'Klik button: Niel, Jokes Niel',
        eventDate: '',
      },
      isLoading: false,  // Properti untuk melacak status loading
      buttonText: 'Niel, Jokes Niel',  // Properti untuk mengubah teks tombol
      projectImages: [
        {
          id: 1,
          img: require('@/assets/images/botoniel/ss-01.jpg'),
        },
        {
          id: 2,
          img: require('@/assets/images/botoniel/ss-02.jpg'),
        },
        {
          id: 3,
          img: require('@/assets/images/botoniel/ss-03.jpg'),
        },
      ],
      projectInfo: {
        projectDetailsHeading: 'Behind The Story: Kumpulan Humor Ala Oniel',
        projectDetails: [
          {
            id: 1,
            details:
              'Ide buku jokes Oniel sebenarnya bukan murni dari saya, tetapi pertama kali tercetus oleh Freya di MC Seishun Girls 29 Juli 2022. Karena belum ada yang merealisasikan dan terpancing kembali oleh sebuah reply di Januari 2023, ide ini akhirnya coba diwujudkan',
          },
          {
            id: 2,
            details:
              'Eksekusi dimulai sekitaran akhir Januari 2023. Dimulai dari mengumpulkan video kompilasi jokes Oniel yang beredar di YouTube maupun Tiktok. Saya sangat berterima kasih dan hats off bagi para konten kreator dan timestamp-er yang telah mengumpulkan momen-momennya.',
          },
          {
            id: 3,
            details:
              'Setelah terkumpul, baru dilakukan penyaringan. Penyaringan pertama dalam memilih jokes yang akan dibukukan adalah memastikan apakah jokes tersebut tetap lucu saat konteksnya dibawa dalam bentuk teks. Dengan asumsi bahwa pembaca bukunya tidak mengikuti Oniel sebelumnya. Setelahnya adalah penyaringan kedua, dengan memastikan tanggal dan event/post jokes tersebut keluar. Di sini lumayan menguras tenaga dan waktu, karena harus mencari dan menonton satu per satu video/cuplikan event untuk memastikan jokes tersebut benar-benar riil dari Oniel',
          },
          {
            id: 4,
            details:
              'Karena libur kuliah, pengerjaannya bisa ngebut hanya dalam dua minggu. Setelah kuliah, proyek ini sempat dikesampingkan. Gift tersebut diserahkan saat MnG Festival Nice To See You (Mei 2023), namun hampir 2 bulan tidak ada kabar hingga Juli 2023.',
          },
          {
            id: 5,
            details:
              '18 Juli, buku tersebut terlihat di antara fanletter yang baru di-share di Instagram Story. Baru pada 24 Juli 2023, ia mengunggah Instagram Story yang memamerkan gift buku jokes Oniel dan mendapat respon positif dari fandom maupun member.',
          },
        ],
      },
    };
  },
  computed: {
    tweetUrl() {
      const message = `Humor @C_OnielJKT48 from #BotOniel \n \n"${this.data.jokes}" \n${this.data.eventDate}`;
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;  // Set status loading menjadi true
      this.buttonText = 'Loading...';  // Ganti teks tombol menjadi "Loading..."
      try {
        const response = await axios.get('https://script.google.com/macros/s/AKfycbz5LqkIhDuZ1KlFbajv9nWGjr8Fyh_5He-1itgmqwJmD3Mi9Mr68KpgeiC36ImW2bdc5Q/exec'); // Ganti dengan URL endpoint Anda
        this.data.jokes = response.data.jokes;
        if (this.data.jokes) {
          const formattedDate = this.formatDate(response.data.date);
          this.data.eventDate = `(${response.data.event} - ${formattedDate})`;
        } else {
          this.data.eventDate = '';
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;  // Set status loading menjadi false
        this.buttonText = 'Niel, Jokes Niel';  // Kembalikan teks tombol ke "Get Data"
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('id-ID', options);
    }
  }
};
</script>

<template>
  <!-- App Header -->
  <AppHeader class="items-center mb-24 px-10" /> <!-- hapus mt-24 setelah banner dihapus -->

  <!-- Card Container Start -->
  <div class="container mx-auto">
    <div class="text-center border-b border-primary-light
					dark:border-secondary-dark">
      <p
        class="font-general-semibold text-2xl sm:text-5xl font-semibold text-ternary-dark dark:text-ternary-light mb-4">
        BotOniel Jokes Generator
      </p>
      <h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
        Temukan Jokes Terbaik Oniel di sini!
      </h3>
      <div class="flex flex-col sm:flex-row justify-center md:gap-5 px-5">
        <router-link to="/botoniel/video"
          class="flex justify-center items-center w-full sm:w-96 mt-4 sm:mt-12 mb-4 sm:mb-6 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="video" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Bot Versi Video</span>
        </router-link>
        <router-link to="/botoniel/cari"
          class="flex justify-center items-center w-full sm:w-96 mt-4 sm:mt-12 mb-4 sm:mb-6 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="search" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Cari Jokes Oniel</span>
        </router-link>
        <router-link to="/botoniel/quote"
          class="flex justify-center items-center w-full sm:w-96 mt-4 sm:mt-12 mb-4 sm:mb-6 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="message-square" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Cari Quote Oniel</span>
        </router-link>
        <a href="https://t.me/botnielbot" target="_blank"
          class="flex justify-center items-center w-full sm:w-96 mt-4 sm:mt-12 mb-4 sm:mb-6 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
          <i data-feather="send" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">Akses Bot Telegram</span>
        </a>
      </div>
    </div>
    <div class="container mt-10 sm:gap-10 p-5">
      <div
        class="md:mt-10 h-92 p-5 border border-indigo-200 dark:border-ternary-dark shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h1 class="font-general-semibold text-4xl mt-2">{{ this.data.jokes }}</h1>
        </div>
        <p class="mt-2 text-xl p-5">{{ this.data.eventDate }}</p>
        <div class="flex justify-center gap-5">
          <a @click="fetchData" :disabled="isLoading"
            class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-xl border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-sky-500 focus:ring-1 focus:ring-sky-900 hover:bg-sky-50 text-white hover:text-sky-500 duration-500">
            <span class="text-sm sm:text-lg font-bold duration-100">{{ buttonText }}</span></a>
          <!-- Tweet Button -->
          <a v-if="data.jokes !== 'Klik button: Niel, Jokes Niel'" :href="tweetUrl" target="_blank" rel="noopener"
            class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-xl border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-blue-500 focus:ring-1 focus:ring-blue-900 hover:bg-blue-50 text-white hover:text-blue-500 duration-500">
            <svg class="w-5 h-5 mr-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M22.46 6c-.77.35-1.6.59-2.46.7a4.28 4.28 0 001.88-2.37 8.72 8.72 0 01-2.76 1.06 4.2 4.2 0 00-7.17 3.83 11.92 11.92 0 01-8.64-4.38 4.19 4.19 0 001.3 5.6 4.22 4.22 0 01-1.91-.53v.05c0 2.07 1.48 3.81 3.45 4.21a4.24 4.24 0 01-1.9.07 4.2 4.2 0 003.92 2.92A8.46 8.46 0 012 18.58a11.92 11.92 0 006.29 1.84c7.55 0 11.68-6.26 11.68-11.68 0-.18-.01-.35-.02-.53A8.36 8.36 0 0022.46 6z" />
            </svg>
            <span class="text-sm sm:text-lg font-bold duration-100">Tweet This</span>
          </a>
        </div>
      </div>
    </div>

    <div class="w-full text-left mt-10 hidden md:block p-5">
      <ProjectGallery :projectImages="projectImages" />
      <p
        class="font-general-medium text-primary-dark dark:text-primary-light text-2xl font-bold mb-10 mt-14 text-center">
        {{ projectInfo.projectDetailsHeading }}
      </p>
      <p v-for="projectDetail in projectInfo.projectDetails" :key="projectDetail.id"
        class="font-general-regular mb-5 text-lg text-ternary-dark dark:text-ternary-light text-justify">
        {{ projectDetail.details }}
      </p>
    </div>
    <div class="flex flex-col sm:flex-row justify-center gap-5 p-5">
      <a href="https://x.com/mrtjam7lewat12/status/1683474891658391558" target="_blank"
        class="flex justify-center items-center w-full sm:w-96 mt-4 sm:mt-12 mb-4 sm:mb-6 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
        <i data-feather="book-open" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
        <span class="text-sm sm:text-lg font-general-medium duration-100">Buku: Kumpulan Humor Ala Oniel</span></a>
      <a href="https://x.com/mrtjam7lewat12/status/1684547783406256129" target="_blank"
        class="flex justify-center items-center w-full sm:w-96 mt-4 sm:mt-12 mb-4 sm:mb-6 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
        <i data-feather="server" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
        <span class="text-sm sm:text-lg font-general-medium duration-100">Behind The Scene</span></a>
    </div>
    <AppFooter />
  </div>
</template>

<style scoped></style>