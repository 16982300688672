<script>
import AppHeader from '@/components/shared/AppHeader';
import AppFooter from '@/components/shared/AppFooterNonHome.vue';
import MapChart from '@/components/maps/MapChart.vue';
import Accordion from '@/components/reusable/Accordion.vue';
import axios from 'axios';

export default {
  name: 'WhatSongOnielPlay',
  components: {
    AppHeader,
    MapChart,
    Accordion,
    AppFooter,
  },
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    axios.get('https://script.google.com/macros/s/AKfycbzpYlJYAMfw2PhJLyd1-U_UR5M4Dw4skk65r2L31gXxrSaflert01okr9oqUzEDCBMz/exec')
      .then(response => {
        const rawData = response.data;
        const formattedData = rawData.reduce((acc, item) => {
          return { ...acc, ...item };
        }, {});
        this.data = formattedData;
        this.loading = false;
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('id-ID', options);
    }
  }
};
</script>

<template>
  <!-- App Header -->
  <AppHeader class="items-center mb-24 px-10" />

  <!-- Card Container Start -->
  <div class="container mx-auto">
    <div class="text-center border-b border-primary-light
					dark:border-secondary-dark">
      <h1
        class="font-general-semibold text-2xl sm:text-5xl font-semibold text-ternary-dark dark:text-ternary-light mb-4">
        What Song Oniel Play?
      </h1>
      <h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
        Lagu apa yang Oniel putar di live?
      </h3>
    </div>

    <div class="flex justify-center gap-5 mb-10 p-5">
      <router-link to="/what-song-oniel-play/cari"
        class="flex justify-center items-center w-96 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-sky-500 text-gray-500 hover:text-white duration-500">
        <i data-feather="search" class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"></i>
        <span class="text-sm sm:text-lg font-general-medium duration-100">Cari List Lagu Oniel</span>
      </router-link>
    </div>

    <h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mt-4
				">
      Last Data Update: {{ formatDate(data.last_live) }}
    </h3>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-5 sm:gap-10">
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Minutes Live</h3>
        </div>
        <p class="text-bold text-6xl mt-2 text-md p-5">{{ data.total_min }}</p>
      </div>
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Live: Personal Account</h3>
        </div>
        <p class="text-bold text-6xl mt-2 text-md p-5">{{ data.total_live }}</p>
      </div>
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Live: Showroom</h3>
        </div>
        <p class="text-bold text-6xl mt-2 text-md p-5">{{ data.total_sr }}</p>
      </div>
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Live: IDN App</h3>
        </div>
        <p class="text-bold text-6xl mt-2 text-md p-5">{{ data.total_idn }}</p>
      </div>

    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 p-5 sm:gap-10">
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Played Song</h3>
        </div>
        <p class="text-bold text-6xl mt-2 text-md p-5">{{ data.total_song }}</p>
      </div>
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Played Title Song</h3>
        </div>
        <p class="text-bold text-6xl mt-2 text-md p-5">{{ data.total_title }}</p>
      </div>
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Total Played Artist</h3>
        </div>
        <p class="text-bold text-6xl mt-2 text-md p-5">{{ data.total_artist }}</p>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-5 sm:gap-10">
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Top 5 Most Played Song</h3>
        </div>
        <p class="text-left text-bold text-2xl mt-2 ml-10">1. {{ data.song1 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">2. {{ data.song2 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">3. {{ data.song3 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">4. {{ data.song4 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">5. {{ data.song5 }}</p>
      </div>
      <div
        class="mt-5 h-84 border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-secondary-light dark:bg-ternary-dark text-gray-500 dark:text-white">
        <div class="flex flex-row justify-center">
          <h3 class="font-general-semibold text-2xl font-semibold mt-2 mb-3 px-4">Top 5 Most Played Artist</h3>
        </div>
        <p class="text-left text-bold text-2xl mt-2 ml-10">1. {{ data.artist1 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">2. {{ data.artist2 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">3. {{ data.artist3 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">4. {{ data.artist4 }}</p>
        <p class="text-left text-bold text-2xl mt-2 ml-10">5. {{ data.artist5 }}</p>
      </div>
    </div>
    <div class="sm:w-full p-5">
      <MapChart />
    </div>
    <div class="w-full p-5">
      <iframe style="border-radius:12px;"
        src="https://open.spotify.com/embed/playlist/6uk4wUtkUwFDCGXEn6cBqe?utm_source=generator" width="100%"
        height="500" frameBorder="0" allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
    <Accordion />

    <p class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-xs
					sm:text-sm
					font-normal
					my-4 mx-8
				">
      Seluruh data terkait rekap <b>#WhatSongOnielPlay</b> dan rekap <b>#OnieLand</b> dapat digunakan secara bebas tanpa
      batasan hak cipta.
      Silakan gunakan, bagikan, atau modifikasi sesuai kebutuhan Anda. Kami sangat mengapresiasi jika anda memberikan
      kredit kepada kami pada project atau karya yang anda buat menggunakan data tersebut
    </p>

    <AppFooter />
  </div>
</template>

<style scoped></style>