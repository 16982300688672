<script>
import AppHeader from '@/components/shared/AppHeader';
import AppFooter from '@/components/shared/AppFooterNonHome.vue';
import axios from 'axios';

export default {
  name: 'Onieland',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      minDate: '2021-03-28',
      data: [],
      filteredData: [],
      paginatedData: [],
      currentPage: 1,
      customPage: 1,
      pageSize: 15,
      filters: {
        date: '',
        title: '',
        artist: ''
      }
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredData.length / this.pageSize);
    }
  },
  async created() {
    const sheetId = '1gnaea7d9v0iytISWJXHLCNsr_zObx1C2fLv1DaMksR4';
    const sheetName = 'Onieland'; // Nama tab pada Google Sheets
    const apiKey = 'AIzaSyAWtgIDV5mt3A9ZjKu-gMsrAZhuTVPvYj8';

    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`
      );
      this.data = response.data.values
      this.filteredData = this.data;
      this.updatePagination();
    } catch (error) {
      console.error('Error fetching Google Sheet data:', error);
    }
  },
  methods: {
    formatTanggalIndo(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('id-ID', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
    },
    applyFilters() {
      this.filteredData = this.data.filter(row => {
        const dateMatch = !this.filters.date || row[0] === this.formatTanggalIndo(this.filters.date);
        const searchMatch = !this.filters.search ||
          row[1].toLowerCase().includes(this.filters.search.toLowerCase()) ||
          row[2].toLowerCase().includes(this.filters.search.toLowerCase());
        return dateMatch && searchMatch;
      });
      this.currentPage = 1;
      this.updatePagination();
    },
    updatePagination() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.paginatedData = this.filteredData.slice(start, end);
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updatePagination();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updatePagination();
      }
    },
    goToFirstPage() {
      this.currentPage = 1;
      this.updatePagination();
    },
    goToLastPage() {
      this.currentPage = this.totalPages;
      this.updatePagination();
    },
    goToCustomPage() {
      if (this.customPage >= 1 && this.customPage <= this.totalPages) {
        this.currentPage = this.customPage;
        this.updatePagination();
      } else {
        this.customPage = this.currentPage; // Reset jika input salah
      }
    }
  },
  watch: {
    currentPage(value) {
      this.customPage = value; // Update customPage saat currentPage berubah
    }
  }
};
</script>

<template>
  <!-- App Header -->
  <AppHeader class="items-center mb-24 px-10" />

  <!-- Card Container Start -->
  <div class="container mx-auto">
    <div class="text-center border-b border-primary-light
					dark:border-secondary-dark">
      <p
        class="font-general-semibold text-2xl sm:text-5xl font-semibold text-ternary-dark dark:text-ternary-light mb-4">
        #OnieLand Recap Song
      </p>
      <h3 class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				">
        Rekap #OnieLand dan sesi Live Karaoke Oniel
      </h3>
    </div>
    <div class="mx-auto max-w-screen-xl px-4 lg:px-12 mt-10">
      <p class="font-general-regular
					text-justify text-secondary-dark
					dark:text-ternary-light
					text-xs
					sm:text-sm
					font-normal
					my-4
				">
        Seluruh data terkait rekap <b>#WhatSongOnielPlay</b> dan rekap <b>#OnieLand</b> dapat digunakan secara bebas
        tanpa
        batasan hak cipta.
        Silakan gunakan, bagikan, atau modifikasi sesuai kebutuhan Anda. Kami sangat mengapresiasi jika anda memberikan
        kredit kepada kami pada project atau karya yang anda buat menggunakan data tersebut
      </p>
      <!-- Start coding here -->
      <div class="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
        <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
          <div class="w-full md:w-1/2">
            <form class="flex items-center">
              <div class="relative w-full mr-1">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                    viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
                <input
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  v-model="filters.search" @input="applyFilters" type="text" placeholder=" Cari berdasarkan judul lagu/artis">
              </div>
            </form>
          </div>
          <div
            class="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <div class="flex items-center space-x-3 w-full md:w-auto">
              <input
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                type="date" v-model="filters.date" :min="minDate" @input="applyFilters" />
            </div>
          </div>
        </div>
        <div class="overflow-x-auto">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-4 py-3">Title Song</th>
                <th scope="col" class="px-4 py-3">Artist</th>
                <th scope="col" class="px-4 py-3">Platform</th>
                <th scope="col" class="px-4 py-3">Live Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in paginatedData" :key="index" class="border-b dark:border-gray-700">
                <td class="px-4 py-3">{{ row[1] }}</td>
                <td class="px-4 py-3">{{ row[2] }}</td>
                <td class="px-4 py-3">{{ row[3] }}</td>
                <td class="px-4 py-3">{{ row[0] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav class="flex flex-col md:flex-row justify-between items-end md:items-center space-y-3 md:space-y-0 p-4"
          aria-label="Table navigation">
          <ul class="inline-flex items-stretch -space-x-px">
            <li>
              <button @click="goToFirstPage" :disabled="currentPage === totalPages"
                class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Previous</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M785.493333 707.84L589.653333 512l195.84-195.84L725.333333 256 469.333333 512l256 256zM256 256h85.333333v512h-85.333333z" />
                </svg>
              </button>
            </li>
            <li>
              <button @click="prevPage" :disabled="currentPage === 1"
                class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Previous</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M657.706667 316.373333L597.333333 256 341.333333 512l256 256 60.373334-60.373333L462.08 512z" />
                </svg>
              </button>
            </li>
            <li>
              <span class="px-3 text-black dark:text-white">Page <input type="number"
                  class="w-12 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  v-model="customPage" @change="goToCustomPage" /> of {{ totalPages }}</span>
            </li>
            <li>
              <button @click="nextPage" :disabled="currentPage === totalPages"
                class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Previous</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M426.666667 256l-60.373334 60.373333L561.92 512l-195.626667 195.626667L426.666667 768l256-256z" />
                </svg>
              </button>
            </li>
            <li>
              <button @click="goToLastPage" :disabled="currentPage === totalPages"
                class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span class="sr-only">Previous</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M238.506667 316.16L434.346667 512l-195.84 195.84L298.666667 768l256-256-256-256zM682.666667 256h85.333333v512h-85.333333z" />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <AppFooter />
</template>

<style scoped></style>